
import { mapState } from 'vuex'

export default {
  props: {
    blok: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapState(['user', 'homepageTopAlertContentCards']),
    cards() {
      return [...this.homepageTopAlertContentCards]
    }
  },
  mounted (){
    if(this.cards.length){
      this.$store.commit('logContentCardImpressions', this.cards)
    }
  },
  methods: {
    convertExpirationToChip(expiresAt) {
      let today = new Date()
      let isToday = today.getDate() === expiresAt.getDate() && today.getMonth() === expiresAt.getMonth() && today.getFullYear() === expiresAt.getFullYear()

      let timeDifference = Math.abs(expiresAt.getTime() - today.getTime())
      let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

      // Format hours and minutes for a more precise time (e.g., 11:55 PM)
      let expiresTime = expiresAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

      if (isToday) {
        return `TODAY UNTIL ${expiresTime}`
      } else if (daysDifference < 7) {
        let expiresDay = expiresAt.toLocaleString('en-us', { weekday: 'long' })
        return `UNTIL ${expiresTime} ${expiresDay}`
      } else {
        let expiresDate = expiresAt.toLocaleString('en-US', { month: 'numeric', day: 'numeric' })
        return `UNTIL ${expiresTime} ${expiresDate}`
      }
    },
    convertExpirationToTimeRemaining(expiresAt) {
      const now = new Date()

      const remaining = expiresAt.getTime() - now.getTime()
      if (remaining < 0) {
        return 'EXPIRED'
      }

      // Calculate days, hours, and minutes left
      const dd = Math.floor(remaining / (1000 * 60 * 60 * 24))
      const hh = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const mm = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))

      let message = ''
      message += dd > 0 ? `${dd} DAYS ` : ''
      message += hh > 0 ? `${hh} HOURS ` : ''
      message += mm > 0 ? `${mm} MINUTES ` : ''
      return message + 'LEFT'
    }
  }
}
